<template>
  <div>
    <div class="h-separator">
      <label class="title">CONFIGURAR TABELAS DE SERVIÇOS</label>
      <span></span>
    </div>
    <b-row>
      <b-col cols="4">
        <b-form-group>
          <div class="h-separator">
            <label class="title">Serviço</label>
            <span></span>
          </div>
          <b-form-select
            @input="selectedService"
            :options="servicesOptions"
            v-model="selected"
          >
            <template #first>
              <b-form-select-option :value="undefined" disabled
                >Por favor, selecione um serviço</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col cols="2" v-show="hasData">
        <b-form-group>
          <div class="h-separator">
            <label class="title">Peso Unidade</label>
            <span></span>
          </div>
          <b-form-select
            :options="units"
            @input="updateRangesUnits"
            v-model="unit"
          >
            <template #first>
              <b-form-select-option :value="undefined" disabled
                >Unidade</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col cols="6" v-show="hasData">
        <b-form-group>
          <div class="h-separator">
            <label class="title">Copiar Ranges para outro usuário</label>
            <span></span>
          </div>
          <div
            class="d-flex flex-row justify-content-around align-items-center"
          >
            <b-form-select :options="allUsers" v-model="userToCopyId">
              <template #first>
                <b-form-select-option :value="undefined" disabled
                  >Selecione um usuário para clonar a tabela
                  atual</b-form-select-option
                >
              </template>
            </b-form-select>
            <b-button @click="copyRanges" variant="outline-success"
              >Copiar</b-button
            >
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="12" class="service">
        <!--<ServiceTable></ServiceTable> -->

        <div v-show="selected">
          <b-table
            sticky-header="100vh"
            style="padding-bottom: 16px"
            :items="items"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :select-mode="selectMode"
            selectable
            show-empty
          >
            <template v-slot:cell()="{ value, item, field: { key } }">
              <template v-if="edit != item.id">{{ value }}</template>
              <template v-else>
                <b-form-select
                  v-if="key === 'type'"
                  :options="field"
                  v-model="item[key]"
                >
                  <template #first>
                    <b-form-select-option :value="undefined" disabled
                      >Tipo de faixa</b-form-select-option
                    >
                  </template>
                </b-form-select>
                <b-form-input v-if="key != 'type'" v-model="item[key]" />
              </template>
            </template>
            <template v-slot:cell(actions)="{ item: { id }, item }">
              <div class="btn-icons btn-actions">
                <i
                  v-if="edit !== id"
                  @click="onEdit(id)"
                  class="material-icons btn-icon"
                  >edit</i
                >
                <i
                  v-else
                  @click="onSave(item, id)"
                  class="material-icons btn-icon"
                  >check</i
                >
                <i
                  @click="deleteRange(id)"
                  class="material-icons btn-icon btn-icon-red ml-2"
                  >delete</i
                >
              </div></template
            >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            size="md"
            align="right"
            class="my-0"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import api from "@/services";
import { toast, errorFormater } from "@/utils";
import redirectorservice from "../../store/modules/home/redirectorservice";

export default {
  modules: {
    redirectorservice: redirectorservice,
  },
  data: () => ({
    services: [],
    selected: undefined,
    totalRows: 1,
    currentPage: 1,
    perPage: 4,
    selectMode: "single",
    modalShow: false,
    edit: null,
    items: [],
    val: "",
    userToCopyId: undefined,
    unit: undefined,
    unitLocked: true,
    units: [
      { value: "ounce", text: "Ounce" },
      { value: "gram", text: "Gram" },
      { value: "pound", text: "Pound" },
      { value: "kilogram", text: "Kilogram" },
    ],
  }),
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  beforeMount() {
    api.get("service/index").then((response) => {
      this.services = response.data.services;
    });
  },
  computed: {
    fields() {
      return redirectorservice.state.fields;
    },
    field() {
      return redirectorservice.state.field;
    },
    servicesOptions() {
      const sTexts = [
        { value: "BPSI-01", text: "Prime (Postal)" },
        { value: "BPSI-08", text: "Packet Standard (Postal)" },
        { value: "BPSI-02", text: "Packet Express (Postal)" },
        { value: "BPSI-03", text: "Express Plus (Courier)" },
        { value: "BPSI-22", text: "Express Plus RX" },
        { value: "BPSI-11", text: "Tracked Parcel" },
      ]
      return this.services.map((s) => ({ value: s.description, text: sTexts.find(a => a.value === s.description)?.text || 'BPSI Unknown'}));
    },
    allUsers() {
      return this.$store.state.redirectors?.redirectors
        .filter((u) => u.id != this.user.id)
        .map((u) => ({
          value: u.id,
          text: u.name,
        }));
    },
    hasData() {
      return this.totalRows > 0;
    },
  },
  mounted() {
    this.totalRows = this.items.length;
  },

  methods: {
    updateRangesUnits(unit) {
      if (this.unitLocked) return;

      let e = null;
      this.items.forEach((i) => {
        api.put("/service/updaterange/" + i.id, { ...i, unit }).then(
          (response) => {
            if (response.status < 200 || response.status >= 300) {
              e = true;
            }
          },
          (error) => {
            e = error;
            return Promise.reject(error);
          }
        );
      });
      if (e) {
        toast(
          this,
          "danger",
          "Unidade de Peso não atualizada",
          errorFormater(e)
        );
      } else {
        toast(
          this,
          "success",
          "Unidade de Peso atualizada",
          "Unidade de Peso atualizada com sucesso"
        );
      }
    },
    copyRanges() {
      if (!this.userToCopyId) {
        toast(
          this,
          "info",
          "Usuário não selecionado",
          "Selecione um usuário para continuar"
        );
        return;
      }
      api
        .post("service/copy", {
          service_id: this.services.find((s) => s.description === this.selected)
            ?.id,
          from: this.user.id,
          to: this.userToCopyId,
        })
        .then(
          () => {
            toast(
              this,
              "success",
              "Ranges clonado",
              "A tabela atual foi clonada para o usuário selecionado"
            );
          },
          (error) => {
            toast(this, "danger", "Ranges não copiado", errorFormater(error));
            return Promise.reject(error);
          }
        );
    },

    onEdit(id) {
      this.edit = this.edit !== id ? id : null;
    },

    onSave(item, id) {
      this.edit = this.edit !== item ? item : null;
      for (const [key, value] of Object.entries(item)) {
        item[key] = !value ? null : value;
      }

      let form = item;

      api.put("/service/updaterange/" + id, form).then(
        (result) => {
          if (result.status === 200) {
            toast(
              this,
              "success",
              "Serviço atualizado",
              "Nenhum problema foi encontrado."
            );
          }

          return this.items;
        },
        (error) => {
          toast(
            this,
            "danger",
            "Serviços não atualizado",
            errorFormater(error)
          );
          return Promise.reject(error);
        }
      );
    },

    selectedService(e) {
      let service = this.services.find((service) => service.description === e);

      api
        .get(
          "/service/indexrange?user_id=" +
            this.user.id +
            "&service_id=" +
            service.id
        )
        .then(
          (result) => {
            if (result.status === 200) {
              this.items = result.data.service_ranges.sort(
                (a, b) => a.value - b.value
              );
              this.totalRows = this.items.length;
              this.unit = this.items[0].unit;
              setTimeout(() => {
                this.unitLocked = false;
              }, 100);

              return this.items;
            }
          },
          (error) => {
            toast(this, "danger", "Serviços não obtidos", errorFormater(error));
            return Promise.reject(error);
          }
        );
    },
    deleteRange(id) {
      api.delete("/service/deleterange/" + id).then(
        (result) => {
          if (result.status === 204) {
            this.items = this.items.filter((i) => i.id !== id);
            this.totalRows = this.items.length;
            toast(
              this,
              "success",
              "Deletado com sucesso",
              "Nenhum problema foi encontrado."
            );
          }
        },
        (error) => {
          toast(this, "danger", "Erro ao deletar", errorFormater(error));
          return Promise.reject(error);
        }
      );
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '../../style/main/mixins.styl';
@import '../../style/main/colors.styl'

.h-separator

    display flex

    .title
        padding 0px 10px
        font-size 14px
        color light
        font-weight bold
        border-radius 1rem
        background primary

    span
        align-self center

    span:last-child
        border 1px dashed primary
        height 1px

    span:last-child
        flex-grow 1
.btn
  justify-content space-between
  margin-left 10px
  float left

.btn-service
  float left
  margin-left 1px
  border-radius 50%
  margin-top 20px

.service
  width 50%
  float right

.btn-icon-red
    background-color red !important

.table
    color greyLight !important
    border-collapse separate
    border-spacing 0 1em



.table.b-table > thead > tr > .table-b-table-default, .table.b-table > tbody > tr > .table-b-table-default, .table.b-table > tfoot > tr > .table-b-table-default
    background-color light !important
    color black

.table td, .table th, .table thead th
    border none
    white-space nowrap

.table thead th
    padding 0 .75rem
    font-size 20px

.table tr
    //background-color content-base-theme()
    background-color primary
    transition .4s

radius = 10px

.table tr td:first-child
    border-top-left-radius radius
    border-bottom-left-radius radius
    width 8%

.table tr td:last-child
    border-top-right-radius radius
    border-bottom-right-radius radius

.table th div
    nowrap()

.table.b-table > tbody > .table-active, .table.b-table > tbody > .table-active > th, .table.b-table > tbody > .table-active > td
    background-color secondary !important

.table.b-table tr
    .btn-actions
        min-width 64px
    i
        opacity 0
        transition .3s
        background-color green

    &:hover
        i
            opacity 1
</style>
