import { dateFormat, upperCase /* , captalize */ } from "@/utils";

const services = [
  { value: "BPSI-01", text: "Prime (Postal)" },
  { value: "BPSI-08", text: "Packet Standard (Postal)" },
  { value: "BPSI-02", text: "Packet Express (Postal)" },
  { value: "BPSI-03", text: "Express Plus (Courier)" },
  { value: "BPSI-22", text: "Express Plus RX" },
  { value: "BPSI-11", text: "Tracked Parcel" },
]

export default {
  namespaced: true,
  state: {
    // Info
    packets: [],
    itemsKey: "packets",
    title: "Pacotes Enviados",
    name: "Enviar Pacote",
    //linkTo: "sendbox.sender",
    //btnLabel: "Enviar Pacote",
    loading: true, //when request is call turn this to true
    hasLink: true,
    unselectable: true,
    // API Get index
    get: {
      urn: "packet/index",
      response: "packets",
    },
    async_paginate: true,
    // API routes
    routes: [
      {
        key: "add",
        form: "base",
        title: "Add New Packet",
        success: "New Packet has been registered",
        urn: "sendbox/create",
        response: "packet",
        method: "post",
        dispatch: true,
      },
    ],
    // Table fields
    fields: [
      //{ key: "url", label: "Etiqueta URL" },
      { key: "status", label: "Status" },
      { key: "code_shipment", label: "Remessa" },
      { key: "paid_out", label: "Pagamento" },
      {
        key: "internal_value",
        label: "Valor Xprex",
        formatter: (p) => (p * 1).toFixed(2),
      },
      {
        key: "insurance_value",
        label: "Seguro",
        formatter: (p) => p ? (p * 1).toFixed(2) : 0,
      },
      { key: "packet_number", label: "Número - Pacote" },
      { key: "service", label: "Serviço - Pacote", formatter: (s) => services.find(a => a.value === s)?.text || '' },
      { key: "tracking", label: "Código de rastreio" },
      {
        key: "tax_modality",
        label: "Modalidade - Pacote",
        formatter: (item) => upperCase(item),
      },
      /* 
      {
        key: "type_package",
        label: "Tipo - Pacote",
        formatter: (item) => captalize(item),
      },
      */
      { key: "weight", label: "Peso" },
      { key: "destino", label: "Destino" },
      { key: "zip", label: "CEP" },
      { key: "sender_name", label: "Nome - Remetente" },
      // { key: "sender_email", label: "Email - Remetente" },
      // { key: "sender_telephone", label: "Telefone - Remetente" },
      { key: "receiver_name", label: "Nome - Destinatário" },
      { key: "receiver_email", label: "Email - Destinatário" },
      { key: "receiver_telephone", label: "Telefone - Destinatário" },
      {
        key: "created_at",
        label: "Data de Criação",
        formatter: (item) => dateFormat(item),
      },
      { key: "value", label: "Valor Declarado" },
      {
        key: "updated",
        label: "Última atualização",
        formatter: (item) => dateFormat(item),
      },
    ],
    //dynamic: true,
    // Form Fields
    //forms: { },
  },
  mutations: {
    set: (state, payload) => {
      state.packets = payload;
      state.loading = false;
    },
    unshift: (state, payload) => {
      state.packets.unshift(payload);
    },
    swap: (state, payload) => {
      state.packets.forEach((element, index) => {
        if (element.id === payload.id) {
          state.packets.splice(index, 1, payload);
        }
      });
    },
    addOrEdit: (state, payload) => {
      let f = false;
      state.packets.forEach((element, index) => {
        if (element.id === payload.id) {
          state.packets.splice(index, 1, payload);
          f = true;
        }
      });
      if (!f) state.packets.unshift(payload);
    },
    url: (state, payload) => {
      state.packets.forEach((element, index) => {
        if (element.id === payload.id) {
          state.packets[index].url = payload.url;
        }
      });
    },
    paidout: (state, payload) => {
      state.packets.forEach((element, index) => {
        if (element.id === payload.id) {
          state.packets[index].paid_out = payload.paid_out;
          state.packets[index].status = "Á enviar";
        }
      });
    },
    remove: (state, payload) => {
      state.packets = state.packets.filter((a) => a.id !== payload);
    },
    reset: state => {
      state.packets = []
      state.loading = true
    }
  },
  actions: {
    init: (context, payload) => {
      context.commit("set", payload);
    },
    add: (context, payload) => {
      context.commit("unshift", payload);
    },
    edit: (context, payload) => {
      context.commit("swap", payload);
    },
    addOrEdit: (context, payload) => {
      context.commit("addOrEdit", payload);
    },
    url: (context, payload) => {
      context.commit("url", payload);
    },
    paidout: (context, payload) => {
      context.commit("paidout", payload);
    },
    remove: (context, payload) => {
      context.commit("remove", payload);
    },
    reset: context => {
      context.commit("reset");
    }
  },
};
